import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyContent = () => {
  const { t } = useTranslation();
  const [points] = useState([
    {
      id: '',
      title: t('Def1'),
    },
    {
      id: '',
      title: t('Def2'),
    },
    {
      id: '',
      title: t('Def3'),
    },
    {
      id: '',
      title: t('Def4'),
    },
    {
      id: '',
      title: t('Def4'),
    },
    {
      id: '',
      title: t('Def5'),
    },
    {
      id: '',
      title: t('Def6'),
    },
    {
      id: '',
      title: t('Def7'),
    },
    {
      id: '',
      title: t('Def8'),
    },
    {
      id: '',
      title: t('Def9'),
    },
    {
      id: '',
      title: t('Def10'),
    },
  ]);

  const [privacyData] = useState([
    {
      id: '',
      title: t('Def1'),
      data: [
        {
          id: 'a',
          name: t('InfoYouProvide'),
          text: t('InfoYouProvideT'),
          isBullet: true,
          points: [
            t('InfoA1'),
            t('InfoA2'),
            t('InfoA3'),
            t('InfoA4'),
            t('InfoA5'),
          ],
        },
        {
          id: 'b',
          name: t('InfoWeCollect'),
          isBullet: true,
          points: [t('InfoB1'), t('InfoB2')],
        },
      ],
    },
    {
      id: '',
      title: t('Def2'),
      data: [
        {
          id: 'a',
          name: t('Communicate'),
          text: t('CommunicateT'),
        },
        {
          id: 'b',
          name: t('ToImprove'),
          text: t('ToImproveT'),
        },
        {
          id: 'c',
          name: t('ToSecure'),
          text: t('ToSecureT'),
        },
        {
          id: 'd',
          name: t('ToMarket'),
          text: t('ToMarketT'),
        },
        {
          id: 'e',
          name: t('LegalProcessing'),
          text: t('LegalProcessingT'),
        },
      ],
    },
    {
      id: '',
      title: t('Def3'),
      data: [
        {
          id: 'a',
          name: t('ServiceProviders'),
          text: t('ServiceProvidersT'),
        },
        {
          id: 'b',
          name: t('CorporateEvents'),
          text: t('CorporateEventsT'),
        },
        {
          id: 'c',
          name: t('PublicForums'),
          text: t('PublicForumsT'),
        },
        {
          id: 'd',
          name: t('CompelledDisclosure'),
          text: t('CompelledDisclosureT'),
        },
      ],
    },
    {
      id: '',
      title: t('Def4'),
      data: [
        {
          id: 'a',
          name: t('TransfersToPalm'),
          text: t('TransfersToPalmT'),
        },
        {
          id: 'b',
          name: t('TransfersToThird'),
          text: t('TransfersToThirdT'),
        },
      ],
    },
    {
      id: '',
      title: t('Def5'),
      data: [
        {
          id: 'a',
          name: t('DataStorage'),
          text: t('DataStorageT'),
        },
        {
          id: 'b',
          name: t('Retention'),
          text: t('RetentionT'),
        },
      ],
    },
    {
      id: '',
      title: t('Def6'),
      text: t('Def6T'),
    },
    {
      id: '',
      title: t('Def7'),
      data: [
        {
          id: 'a',
          name: t('DataRequests'),
          text: t('DataRequestsT'),
        },
        {
          id: 'b',
          name: t('AntiSpamPolicy'),
          text: t('AntiSpamPolicyT'),
        },
      ],
    },
    {
      id: '',
      title: t('Def8'),
      data: [
        {
          id: 'a',
          name: t('Applicability'),
          text: t('ApplicabilityT'),
        },
        {
          id: 'b',
          name: t('InfoCollection'),
          text: t('InfoCollectionT'),
        },
        {
          id: 'c',
          name: t('PublicForums'),
          text: t('PublicForumsT'),
        },
        {
          id: 'd',
          name: t('ExerciseRights'),
          text: t('ExerciseRights'),
        },
      ],
    },
    {
      id: '',
      title: t('Def9'),
      text: t('Def9T'),
    },
    {
      id: '',
      title: t('Def10'),
      data: [
        {
          id: 'a',
          name: t('SellData'),
          text: t('SellDataT'),
        },
        {
          id: 'b',
          name: t('AboutChildren'),
          text: t('AboutChildrenT'),
        },
        {
          id: 'c',
          name: t('DataBreach'),
          text: t('DataBreachT'),
        },
        {
          id: 'd',
          name: t('SocialMedia'),
          text: t('SocialMediaT'),
        },
        {
          id: 'e',
          name: t('BehavioralAdvertise'),
          text: t('BehavioralAdvertiseT'),
        },
      ],
    },
  ]);
  return (
    <div className="mt-[60px] max_md:mt-[50px] max_sm:mt-[30px] flex flex-col gap-[60px] max_md:gap-[50px] max_sm:gap-[30px]">
      <div className="Text18Normal text-TernaryT flex flex-col gap-[30px] max_md:gap-6 max_sm:gap-4">
        <p className="">{t('PrivacyInfo1')}</p>
        <div className="flex gap-2 ml-1">
          <p className="w-[5px] h-[5px] rounded-full bg-TernaryT mt-[10px] max_sm:mt-[7px]"></p>
          <p className="w-[calc(100%-13px)]">{t('PrivacyInfo2')}</p>
        </div>
        <p className="">{t('PrivacyInfo3')}</p>
        <p className="">{t('PrivacyInfo4')}</p>
        <p className="">{t('PrivacyInfo5')}</p>
        <div className="flex gap-2 ml-1">
          <p className="w-[5px] h-[5px] rounded-full bg-PrimaryT mt-[10px] max_sm:mt-[7px]"></p>
          <p className="w-[calc(100%-13px)]">
            <span className="font-semibold text-PrimaryT ">By mail: </span>
            Palm Labs, 25 SE 2nd Ave, Ste 550 Miami, Florida 33131, USA. Attn:
            Privacy and Data Protection Officer.
          </p>
        </div>
        <div className="flex gap-2 items-center ml-1">
          <p className="w-[5px] h-[5px] rounded-full bg-PrimaryT"></p>
          <p className="">
            <span className="font-semibold text-PrimaryT ">Email: </span>
            <span className="text-ButtonPrimary cursor-pointer underline">
              privacy@palmlabs.com
            </span>
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-[30px] max_md:gap-6 max_sm:gap-4">
        {points.map((defination, index) => (
          <div
            className="flex items-center gap-1 Text18Normal text-PrimaryT"
            key={index}
          >
            <p>{index + 1}.</p>
            <p>{defination.title}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-[60px] max_md:gap-[50px] max_sm:gap-[30px]">
        {privacyData.map((allData, index1) => (
          <div key={index1}>
            <h2 className="Text24 font-semibold text-PrimaryT">
              <span className="mr-1">{index1 + 1}.</span>
              {allData.title}
            </h2>
            {allData.text && (
              <p className="Text18Normal text-TernaryT mt-5">{allData.text}</p>
            )}
            {index1 === 8 ? (
              <>
                <div className="flex gap-2 items-center ml-1 mt-[30px] max_md:mt-6 max_sm:mt-4">
                  <p className="w-[5px] h-[5px] rounded-full bg-PrimaryT"></p>
                  <p className="Text18Normal">
                    <span className="font-semibold text-PrimaryT ">
                      Email:{' '}
                    </span>
                    <span className="text-ButtonPrimary cursor-pointer underline">
                      privacy@palmlabs.com
                    </span>
                  </p>
                </div>
                <div className="flex gap-2 ml-1 mt-[30px] max_md:mt-6 max_sm:mt-4">
                  <p className="w-[5px] h-[5px] rounded-full bg-PrimaryT mt-[10px] max_sm:mt-[7px]"></p>
                  <p className="Text18Normal">
                    <span className="font-semibold text-PrimaryT">
                      Postal Address:{' '}
                    </span>
                    <span className="text-TernaryT">
                      <br />
                      Palm Labs, LLC.
                      <br />
                      25 SE 2nd Ave, Ste 550
                      <br />
                      Miami, Florida 33131
                      <br />
                      USA
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <>
                {allData.data && (
                  <>
                    <div className="flex flex-col mt-[30px] max_md:mt-6 max_sm:mt-4 gap-[30px] max_md:gap-6 max_sm:gap-4">
                      {allData.data.map((data, index2) => (
                        <div key={index2}>
                          <h3 className="Text20Semi">
                            <span className="mr-1">{data.id}.</span>
                            {data.name}
                          </h3>
                          {data.text && (
                            <p className="Text18Normal text-TernaryT mt-5">
                              {data.text}
                            </p>
                          )}
                          {data.isBullet && (
                            <div className="flex flex-col gap-[30px] mt-[30px] max_md:mt-6 max_sm:mt-4 max_md:gap-6 max_sm:gap-4 ml-2">
                              {data.points.map((allPoints, index3) => (
                                <div className="flex gap-2" key={index3}>
                                  <p className="w-[6px] h-[6px] rounded-full bg-PrimaryT mt-[10px] max_sm:mt-[7px]"></p>
                                  <p
                                    className="Text18Normal text-TernaryT w-[calc(100%-14px)]"
                                    dangerouslySetInnerHTML={{
                                      __html: allPoints,
                                    }}
                                  ></p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
